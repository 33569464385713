
/*html {
	overflow-y: scroll;
}

::-webkit-scrollbar {
	width: 0px;
	background: transparent;*/ /* make scrollbar transparent */
/*}

     margin: auto;
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;

*/

.fadeOut {
    cursor: pointer;
    max-height: var(--fadeout-max-height);
    overflow: hidden;
}

    .fadeOut:after {
        content: "";
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        pointer-events: none;
        background-image: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255, .95) 95%);
        height: 75%;
        width: 100%;
    }

.fadeIn {
    max-height: var(--fadein-max-height);
    padding-bottom: 3px;
    overflow: hidden;
    transition: max-height var(--fadein-duration-seconds) ease-in;
   /* -webkit-transition: max-height 5.00s ease-in;*/
}


.fadeInArrow {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 200px;
    z-index: 2;
    opacity: 0.75;
}


div.groupbox {
    padding: 10px 10px 10px 10px;
    border: 1px solid #BFBFBF;
    box-shadow: 5px 5px 5px -3px #aaaaaa;
    border-radius: 5px;
}

div.groupbox-editable:hover {
    padding: 9px 9px 9px 9px;
    border: 2px solid #BFBFBF;
    box-shadow: 5px 5px 5px 0px #aaaaaa;
    cursor: pointer;
}

div.polaroid {
    padding: 10px 10px 10px 10px;
    border: 1px solid #BFBFBF;
    background-color: white;
    box-shadow: 10px 10px 5px -3px #aaaaaa;
    cursor: Pointer;
    color: black;
}

    div.polaroid > .caption {
        margin-bottom: 0px;
        font-style: italic;
    }

    div.polaroid > .title {
        font-weight: bold;
        font-size: large;
    }

/*div.polaroid .rotate_right {
        -ms-transform: rotate(7deg);*/ /* IE 9 */
/*-webkit-transform: rotate(7deg);*/ /* Safari */
/*transform: rotate(7deg);
    }


    div.polaroid .div.rotate_left {
        -ms-transform: rotate(-8deg);*/ /* IE 9 */
/*-webkit-transform: rotate(-8deg);*/ /* Safari */
/*transform: rotate(-8deg);
    }*/


.centerAbsoluteElementHorizontallyAndVertically {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.centerAbsoluteElementVertically {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.centerAbsoluteElementHorizontally {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
}

.glow-button:hover {
    transform: scale(1.10);
}

.glow-button {
    transition: transform .5s; /* Animation */
    cursor: pointer;
}

.grid {
    display: grid;
    /* grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));*/ /* 200 */
    grid-gap: 20px;
    align-items: center;
}

    .grid img {
        border: 1px solid #ccc;
        box-shadow: 2px 2px 6px 0px rgba(0,0,0,0.3);
        max-width: 100%;
    }

@media screen and ( min-width: 320px ) {
    img.responsive {
        width: 250px;
    }

    .grid {
        grid-template-columns: repeat(auto-fill, minmax(124px, 1fr)); /* 200 */
    }
}

@media screen and ( min-width: 600px ) {
    img.responsive {
        width: 468px;
    }

    .grid {
        grid-template-columns: repeat(auto-fill, minmax(234px, 1fr)); /* 200 */
    }
}

@media screen and ( min-width: 1024px ) {

    img.responsive {
        width: 800px;
    }

    .grid {
        grid-template-columns: repeat(auto-fill, minmax(400px, 1fr)); /* 200 */
    }
}

@media screen and ( min-height: 1px ) {

    img.responsive {
        max-height: 50px;
    }
}

@media screen and ( min-height: 200px ) {

    img.responsive {
        max-height: 100px;
    }
}

@media screen and ( min-height: 400px ) {

    img.responsive {
        max-height: 300px;
    }
}

@media screen and ( min-height: 600px ) {

    img.responsive {
        max-height: 500px;
    }
}

@media screen and ( min-height: 800px ) {

    img.responsive {
        max-height: 700px;
    }
}

@media screen and ( min-height: 1000px ) {

    img.responsive {
        max-height: 900px;
    }
}

@media screen and ( min-height: 1200px ) {

    img.responsive {
        max-height: 1100px;
    }
}

/*img.responsive {
    height: auto;
}*/
