.error-msg {
    color: #dc3545;
}

.absolute-bg {
    background-color: var(--bs-body-bg);
}

.k-animation-container,
.k-list-container {
    z-index: 10003;
}

.k-avatar {
    margin-right: 3px;
}

.property-panel-title {
    font-size: 20px;
    margin-bottom: 8px;
    font-weight: bold;
    text-decoration: underline;
}

.property-panel-title-nodecoration {
    font-size: 20px;
    margin-bottom: 8px;
    font-weight: bold;
}

.editable-field {
    min-height: 32px;
}

    .editable-field:hover {
        background-color: rgba(127, 127, 127, 0.2);
        border-color: rgba(127, 127, 127, 0.5);
    }

th.k-header.active > span > div > span {
    color: #fff;
    background-color: #007bff;
}

.k-stepper .k-step-text {
    min-width: 300px;
}

.k-stepper .k-step-link {
    cursor: pointer;
}

/*
 * JSON Pretty
 */

.json-pretty {
    line-height: 1.3;
    color: #AAAAAA;
}

.json-key {
    color: rgb(197, 100, 135);
}

.json-value {
    color: #FD971F;
}

.json-string {
    color: #FD971F;
}

.json-boolean {
    color: #FD971F;
}
