
.agentdivlisting:hover {
    filter: brightness(75%);
}

.agentdivlisting {    
    cursor: pointer;    
    border-radius: 10px;
}

.agentdiv {    
    border-radius: 10px;
}
