th.k-header.active > div > div {
    color: #fff;
    background-color: #007bff;
    border-radius: 5px;
    border: 2px solid;
    opacity: 0.6;
}

.k-icon.k-i-more-vertical {
    padding-left: 8px;
    padding-right: 8px;    
}
